import React, { useState, useEffect } from "react";
import Compressor from 'compressorjs';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
export default function ImageUpload({ value, label, index, updatevalues, readonly }) {

    const [getImage, setImage] = useState(null);
    const [showButton, setShowButton] = useState(true);
    const [compressedFile, setCompressedFile] = useState(null);

    const [base64URL, setbase64URL] = useState(null);
    const [prefix, setPrefix] = useState(false);

    useEffect(() => {
        if (value) {
            // if (value.substring(0, 1) === '"') {
            //     setImage(JSON.parse(value))
            // }
            // else {
            //     setImage(value)
            // } 
            const i = JSON.parse(value);
            setImage(i);
            // setImage(value)
           //  console.log("useEffect:", value.substring(0,40));
            // if (i.substring(0, 10) === "data:image") {
            //     setPrefix(true);
            // }
            // else {
            //     setPrefix(false);
            // }
            setShowButton(false);
        }
        else {
            setImage(null);
            // setImage(value)
            setShowButton(true);

        }
    }, [value]);

    const getBase64 = async (file) => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                console.log("getBase64 Called", reader);
                baseURL = reader.result;
                console.log("getBase64 baseURL: ", baseURL.substring(0, 20));
                resolve(baseURL);
            };
            console.log("getBase64 fileInfo: ", fileInfo);
        });
    };

    const handleFileChange = async (e) => {
        if (e.target.files[0]) {
            await convertToBase64(e.target.files[0]);
        }

    }

    const saveValue = async () => {
        let solution = base64URL;
        setShowButton(false);
        setPrefix(false);
        setImage(solution);
        updatevalues(JSON.stringify(solution), index)
    }

    const convertToBase64 = async (e) => {
        if (e) {

            const reader = new FileReader()

            reader.readAsDataURL(e)

            reader.onload = () => {
                console.log('called: ', reader)
                let solution = reader.result.split("base64,")[1];

                setbase64URL(solution);
                setShowButton(false);
                setImage(solution);
                updatevalues(JSON.stringify(solution), index)
            }
        }
    }

     const handleCompressedUpload = async (e) => {
        const image = e.target.files[0];
        new Compressor(image, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server. 

                setCompressedFile(compressedResult)
                // setImage(URL.createObjectURL(compressedResult));
                // updatevalues(JSON.stringify(URL.createObjectURL(compressedResult)), index)
                // setShowButton(false);
            },
        });
    };

    return (
        <Box variant="section" sx={{ width: '86%', marginY: 2, p: 2, border: '2px solid lightgrey' }}>


            <Typography variant="button" display="block" >
                {label}
            </Typography>
            {showButton && !readonly && (
                <input type="file" onChange={handleFileChange} accept="image/png, image/jpeg" />
            )}
            {getImage && (
                <Box onClick={() => readonly ? null : setShowButton(!showButton)} sx={{ width: '86%', backgroundColor: 'white' }} >
                    {prefix &&
                        <img height="200" src={getImage} />
                    }
                    {!prefix &&
                        <img height="200" src={"data:image/jpeg;base64," + getImage} />
                    }

                    {/* <img height="200" src={file} /> */}
                </Box>
            )}
        </Box>
    );
}

