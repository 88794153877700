import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from './ClearIcon';

export default function TextEdit({ value, label, index, onChange, type, readonly }) {
    return (
        <FormControl sx={{ marginTop: 2, width: '90%' }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment">{label}</InputLabel>
            <OutlinedInput
                id="outlined-adornment"
                type={type}
                value={value}
                InputProps={{
                    autocomplete: "off",
                    readOnly: readonly ? true : false
                  }}
                onChange={(value) => {
                    onChange(value.target.value, index);
                }}
                endAdornment={
                    readonly ? null :
                    <InputAdornment position="end">
                        <IconButton
                            sx={{ color: "black", }}
                            aria-label="toggle password visibility"
                            onClick={() => onChange("", index)}
                            edge="end"
                        >
                            {value ? <ClearIcon /> : null}
                        </IconButton >
                    </InputAdornment> 
                }
                label={label}
            />
        </FormControl>

    );
};