import React, { useState, useEffect, useRef } from 'react';
import FlatList from 'flatlist-react';
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';
import TextEdit from '../components/TextEdit';
import CheckBox from '../components/CheckBox';
import SwitchBox from '../components/SwitchBox';
import CalendarDate from '../components/CalendarDate';

import HeaderStrip from '../components/PrintHeader';
import AutoFill from '../components/AutoFill';
import SignaturePad from '../components/SignaturePad';
import ImageUpload from "../components/ImageUpload";
import CircularProgress from '@mui/material/CircularProgress';
import { useReactToPrint } from 'react-to-print';






// import moment from 'moment-timezone';

export default function Questionnaire(props) {

  const [imageRatio, setimageRatio] = React.useState(0.2);
  const [getIndex, setIndex] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [sectionCnt, setsectionCnt] = React.useState(0);
  const [lookUp, setlookUp] = React.useState([]);
  const [printing, setPrinting] = React.useState(false);

  // const [checkedNormal, setCheckedNormal] = React.useState < boolean > (true);
  const [getSections, setSections] = useState(
    []
  );
  const [getInputs, setInputs] = useState(
    []
  );
  const [getAll, setAll] = useState(
    []
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Questionnaire',
    onAfterPrint: () => console.log('Printed PDF successfully!'),
  });

  useEffect(() => {
    const loadData = async () => {
      await loadFromAPI();
    }
    loadData();
  }, []);

  const loadFromAPI = async () => {
    // const MyTOKEN = base64.encode(this.state.CustomerID + ':' + this.state.CustomerPin);
    const webAddress = 'https://webapi.dynamicquestionnaire.com/api/ClientQuestionnaire/1';
    try {
      const done = fetch(webAddress, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          // 'Access-Control-Allow-Origin' : '*',
          // '"Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
          // 'Authorization': 'Basic ' + MyTOKEN,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("response:", responseJson.sections.length);
          try {
            let sections = responseJson.sections
            let newJSON = [];
            let obj = {};
            let lines = [];
            let j = 0
            for (var i = 0; i < sections.length; i++) {
              obj = { ID: sections[i].ID, Label: sections[i].LABEL, Value: '', Type: sections[i].TYPE }
              newJSON.push(obj);
              lines = sections[i].lines
              if (lines) {
                for (j = 0; j < lines.length; j++) {
                  obj = { ID: lines[j].ID, Label: lines[j].Label, Value: lines[j].Value, Type: lines[j].Type }
                  newJSON.push(obj);
                }
              }
            }
            setInputs(newJSON);
            setLoaded(true);
          }
          catch (error) {
            console.log("Lookup Table Error: ", error);
          }
        })
        .catch((error) => {
          console.log("loadFromAPI 1: " + error);
          // this.setState({
          //   spinner: false,
          //   errmsg: 'Error getting questions',
          // })
        });
    }
    catch (error) {

      console.log("loadFromAPI 2: " + error);
      // this.setState({
      //   spinner: false,
      //   errmsg: 'Error getting questions',
      // })

    }
  }



  const loadLines = async (i) => {
    let section = getSections;
    // console.log("loadLines: ", section[i].lines.length);
    setInputs(section[i].lines);
  }

  /**
  * Updates a property as an object in a FlatList component in React Native.
  * 
  * @param {Array} data - The array of data used in the FlatList.
  * @param {number} index - The index of the item in the FlatList to update.
  * @param {string} prop - The name of the property to update.
  * @param {object} value - The new value to assign to the property.
  * @returns {Array} - The updated data array.
  */
  const saveValue = async (dataValue, index) => {

  };

  const saveEmail = async (dataValue, index) => {

  };

  function setCheckBox(dataValue, index) {
  }

  const saveDate = async (dataValue, index) => {
  };

  const savePhone = async (dataValue, index) => {

  };


  const saveSSNum = async (dataValue, index) => {
  };

  const clearValue = (index) => {
  };

  const getSignature = (label, index) => {
  };

  const getNewSignature = (label, index) => {
  };

  const getPicture = (label, index) => {
  }

  const getNewPicture = (label, index) => {
  }

  function insertRecord(array, index, newItem) {
  }

  const onPressLeft = async () => {

  }

  const moveSection = async (i) => {

  }

  const onPressRight = async () => {

  }

  // const getList = async (lookUpName) => {
  //   await return getLookup(lookUpName)
  // }


  const renderQuestions = (item, index) => {
    return (
      <li key={index} sx={{ alignItems: "center", justifyContent: "center" }}>

        {item.Type === 1 &&
          <Box sx={{ width: '75%' }}>
            <Typography variant="h4" gutterBottom>
              {item.Label}
            </Typography>
          </Box>
        }
        {item.Type === 2 &&
          <Box sx={{ width: '75%' }}>
            <Typography variant="h6" gutterBottom>
              {item.Label}
            </Typography>
          </Box>
        }
        {item.Type === 3 &&
          <Box sx={{ width: '75%' }}>
            <Typography variant="caption" display="block" gutterBottom>
              {item.Label}
            </Typography>
          </Box>
        }
        {item.Type === 4 &&
          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            onChange={saveValue}
            type={'text'}
            readonly={true}
          >
          </TextEdit>
        }

        {item.Type === 5 &&

          <TextEdit
            value={item.Value}
            label={item.Label + " - mm/dd/yyyy"}
            index={index}
            onChange={saveDate}
            type={'numeric'}
            readonly={true}
          >
          </TextEdit>
        }
        {item.Type === 6 &&

          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            onChange={saveSSNum}
            type={'numeric'}
            readonly={true}
          >
          </TextEdit>
        }
        {item.Type === 7 &&

          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            onChange={savePhone}
            type={'numeric'}
            readonly={true}
          >
          </TextEdit>
        }
        {item.Type === 8 &&
          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            onChange={saveEmail}
            type={'numeric'}
            readonly={true}
          >
          </TextEdit>
        }

        {item.Type === 9 &&
          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            onChange={saveValue}
            type={'text'}
            readonly={true}
          >
          </TextEdit>
        }
        {item.Type === 10 &&

          <CheckBox
            callbackFromParent={setCheckBox}
            label={item.Label}
            index={index}
            value={item.Value}
            readonly={true}
          >
          </CheckBox>

        }
        {item.Type === 11 &&
          <SwitchBox
            callbackFromParent={setCheckBox}
            label={item.Label}
            index={index}
            value={item.Value}
            readonly={true}
          >
          </SwitchBox>
        }
        {item.Type === 12 &&
          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            saveValue={saveValue}
            type={'numeric'}
            readonly={true}
          >
          </TextEdit>
        }
        {item.Type === 13 &&

          <CalendarDate
            labeltext={item.Label}
            value={item.Value}
            updateValues={saveValue}
            index={index}
            readonly={true}
          >
          </CalendarDate>

        }
        {(item.Type === 15) &&
          <SignaturePad
            caption={item.Label}
            signature={item.Value}
            updateValues={saveValue}
            index={index}
            readonly={true}
          >
          </SignaturePad>
        }

        {(item.Type === 16) &&
          <ImageUpload
            label={item.Label}
            image={item.Value}
            updatevalues={saveValue}
            index={index}
            value={item.Value}
            readonly={true}
          >
          </ImageUpload>
        }


        {item.Type === 17 &&
          <TextEdit
            value={item.Value}
            label={item.Label}
            index={index}
            onChange={saveValue}
            type={'numeric'}
            readonly={true}
          >
          </TextEdit>
        }

      </li>
    );
  }


  return (
    <Container maxWidth="md">
      {(!loaded === true) &&
        <Box sx={{ flexGrow: 1, }}>
          <Typography variant="h3" gutterBottom>
            Please wait ....
          </Typography>
          <CircularProgress
            size={68}
          // sx={{
          //   color: green[500],
          //   position: 'absolute',
          //   top: -6,
          //   left: -6,
          //   zIndex: 1,
          // }}
          />
        </Box>
      }
      {(loaded === true) &&
        <Box sx={{ flexGrow: 1, }} >

          {(printing === false) &&
            <AppBar position="sticky">
              < HeaderStrip
                titleText={getInputs[0].Label}
                iconLeft={''}

                //iconLeft={'arrow-left'}
                iconRight={''}
                onPressLeft={onPressLeft}
                onPressRight={handlePrint}
                index={getIndex}
                maxIndex={getInputs.length - 1}
              />
            </AppBar>
          }
          <p></p>
          {/* <Paper elevation={3} sx={{ alignItems: "center", paddingX: 3, backgroundColor: 'lightgrey', }}>
            <Box>
              <Typography variant="h4" gutterBottom>
                {getSections[getIndex].LABEL}
              </Typography>
            </Box>
          </Paper> */}

          <Paper elevation={3} sx={{ flexWrap: 'wrap', justifyContent: "center", alignItems: "center", paddingX: 3, paddingY: 3, backgroundColor: 'white', }} ref={componentRef}>
            <FlatList
              // wrapperHtmlTag="div"
              list={getInputs}
              renderWhenEmpty={() => <div>List is empty!</div>}
              renderItem={renderQuestions}
            />
          </Paper>
        </Box>
      }
    </Container>
  );

}
