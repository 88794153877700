import {
  createBrowserRouter, 
  createRoutesFromElements,
  Route, 
  RouterProvider
} from 'react-router-dom'

// pages
import Home from './pages/Home'
// import Main from './pages/Main'
import Main from './pages/Questionnaire'
import Print from './pages/QuestionnairePrint'
import About from './pages/About'
// import Question from './pages/Questionnaire1'

// layouts
import RootLayout from './layouts/RootLayout'
import AppBar from './components/AppBar';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppBar />}>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="Main" element={<Main />} />
      <Route path="Print" element={<Print />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App