import { useState, useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import './SignaturePad.css';
import SignatureCanvas from 'react-signature-canvas'
import Typography from '@mui/material/Typography';

export default function SignaturePad({ caption, signature, updateValues, index, readonly }) {
  const [openModel, setOpenModal] = useState(false);
  const [penColor, setPenColor] = useState("blue");
  const [imageURL, setImageURL] = useState(null);
  const [getLabel, setLabel] = useState('')

  useEffect(() => {
    // if (signature.substring(0, 1) === '"') {
    //   setImageURL(JSON.parse(signature))
    // }
    // else {
    //   setImageURL(signature)
    // }

    if (signature) {
      // console.log("useEffect:", signature.substring(0,50));
      setImageURL(JSON.parse(signature));
    } else {
      setImageURL(null);
    }

    setLabel(caption);

  }, [signature]);

  const colors = ["black", "green", "blue", "red"];
  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    if (URL.length < 131) {
      setImageURL(null);
      updateValues('', index)
    }
    else {
      console.log("create: ", URL.substring(0, 30))
      let solution = URL.split("base64,")[1];

      setImageURL(solution);
      updateValues(JSON.stringify(solution), index)
    }

    setOpenModal(false);
  };

  const download = () => {
    const dlink = document.createElement("a");
    dlink.setAttribute("href", imageURL);
    dlink.setAttribute("download", "signature.png");
    dlink.click();
  };

  const sigCanvas = useRef();
  return (

    <Box variant="section" sx={{ width: '85%', marginY: 2, p: 2, border: '2px solid lightgrey' }}>
      {!imageURL && (

        <Box onClick={() => readonly ? null : setOpenModal(true)} sx={{ width: '90%', backgroundColor: 'white' }}
          variant="section"
        >
          <Typography variant="button" display="block" >
            {getLabel}
          </Typography>
        </Box>
      )}
      {
        imageURL && (
          <>
            <Box onClick={() => readonly ? null : setOpenModal(true)} sx={{ width: '90%', backgroundColor: 'white' }}
              variant="outlined"
            >
              <Typography variant="caption" display="block" >
                {getLabel}
              </Typography>

              {/* <img height="40" src={"data:image/jpeg;base64," + imageURL} /> */}

              <img
                height="40"
                src={`data:image/jpeg;base64,${imageURL}` }
                alt="signature"
                className="signature" 
              />
              {/* <img height="40" src={imageURL} alt="signature" className="signature" /> */}
              <br />

              {/* <button
              onClick={download}
              style={{ padding: '5px', marginTop: '5px' }}
            >Download</button> */}

            </Box>
          </>
        )
      }
      {openModel && (
        <div className="modalContainer">
          <div className="modal">
            {/* <div className="sigPad__penColors">
                <p>Pen Color:</p>
                {colors.map((color) => (
                  <span
                    key={color}
                    style={{
                      backgroundColor: color,
                      border: `${color === penColor ? `2px solid ${color}` : ""}`,
                    }}
                    onClick={() => setPenColor(color)}
                  ></span>
                ))}
              </div> */}
            <div className="sigPadContainer">
              <SignatureCanvas
                penColor={penColor}
                canvasProps={{ className: "sigCanvas" }}
                ref={sigCanvas}
              />
              <hr />

            </div>
            <div className="modal__bottom">
              <button onClick={() => sigCanvas.current.clear()}>Clear</button>
              <button onClick={() => setOpenModal(false)}>Cancel</button>
              <button onClick={create}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </Box>

  );
}

