import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function SwitchBox({ value, label, index, callbackFromParent, readonly }) {
    const [checked, setChecked] = useState(true);
    useEffect(() => {
        if (value) {
            setChecked(value === "true");
        }
        else {
            setChecked(false);
        }
    }, [index]);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        callbackFromParent(event.target.checked, index)
    };

    return (
        <Box variant="section" sx={{ width: '90%', marginTop: 2, p: 0, border: '1px solid lightgrey' }}>

           {/* <FormControl sx={{ marginTop: 2, width: '90%' }} variant="outlined"> */}
                <FormControlLabel control={<Switch
                    checked={checked}
                    onChange={readonly ? null : handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />}
                    labelPlacement="start"
                    label={label} />

            {/* </FormControl> */}
        </Box>
    );
};