import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
//import TextEdit from '../components/TextEdit';

export default function Autofill({ value, label, index, updateValues, list, listName }) {
  const [inputs, setInputs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    const getLookup = async () => {
      try {
        list.forEach(function (item) {

          if (item.NAME === listName) {
            setInputs(item.detail);
            setLoading(false);
          };
        })
      }
      catch (error) {
        console.log("useEffect: ", error);
      }

    }
    getLookup();

  }, []);


  const setValue = async (dataValue) => {
    try {
      if (dataValue === null) {
        dataValue = "";
      }
      console.log('setValue: ', dataValue.label, index)
      updateValues(dataValue.label, index);
    }
    catch (error) {
      console.log("useEffect: ", error);
    }
  }


  if (loading) {
    return (
      '...loading')
  }
  return (
    // <Box sx={{ marginTop: 2, width: '90%' }}>
    <Autocomplete
      sx={{ marginTop: 2, width: '90%' }}
      freeSolo
      clearOnBlur
      disablePortal
      id="combo-box-demo"
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      // isOptionEqualToValue={(option, value) =>
      //   value === undefined || value === "" || option.id === value.id
      // }

      options={inputs}
      renderInput={(params) => <TextField {...params} label={label}
        index={index} />}
    />
    //  </Box>
  );
}

