import { useState, useEffect } from "react";
import Box from '@mui/material/Box';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import GetDate from "react-modern-calendar-datepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BorderedSection from "./BorderedSection";
import moment from "moment";

//export default function CalendarDate({ value, label, index, callbackFromParent }) {

const CalendarDate = ({ value, labeltext, index, updateValues, readonly }) => {
    const [selectedDay, setSelectedDay] = useState(null);
    useEffect(() => {
        if (value) {
            const date = new Date(value)
            setSelectedDay(date)
        }
    }, [index]);

    const handleChange = (value) => {

        const dateString = new Date(value).toLocaleDateString()
        setSelectedDay(value)
        updateValues(dateString, index);
    };

    return (
        <BorderedSection title={selectedDay ? labeltext : ""}>
            {/* <Box variant="section" sx={{ width: '90%', marginTop: 2, p: 0, border: '1px solid lightgrey' }}> */}

            <DatePicker
                wrapperClassName="customDatePicker"
                showIcon
                selected={selectedDay}
                onChange={readonly ? null : (date) => handleChange(date)}
                readOnly={readonly}
                placeholderText={labeltext}
                shouldHighlightWeekends
 //               isClearable
                dateFormat="MM/dd/yyyy"
            />

            {/* </Box> */}
        </BorderedSection>
    );
};

export default CalendarDate;