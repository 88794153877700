import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PrintIcon from '@mui/icons-material/Print';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '..', height: '..' }
class Header extends Component {
	constructor(props) {

		super(props);

		this.state = {
			switchValue: false,
		}

	}



	_onPressButton() {
		console.log("onPressButton");
	}


	render() {

		return (

			<Paper elevation={3} sx={{ alignItems: "center", paddingX: 3, backgroundColor: 'white', }}>
				<Box sx={{ flexGrow: 1 }}>
					<Stack direction="row" spacing={2}>
						<Box sx={{ alignItems: "left", textalign: "center", width: '5%' }}>
							{(this.props.index > 0) &&

								<IconButton aria-label="back"
									onClick={this.props.onPressLeft}>
									<ArrowBackIcon sx={{ color: 'black', fontSize: '3rem' }} />
								</IconButton>
							}

						</Box>
						<Box sx={{ alignItems: "center", width: '90%' }}>
							<Button sx={{ alignItems: "center", width: '100%' }}
								size="large"
								onClick={this._onPressButton}
								variant="text">


								<Typography variant="h4" gutterBottom>
									{this.props.titleText}
								</Typography>
							</Button>

						</Box>
						<Box sx={{ alignItems: "right", width: '5%' }}>
							<IconButton aria-label="back"
								onClick={this.props.onPressRight}>
								<PrintIcon sx={{ color: 'black', fontSize: '3rem' }} />
							</IconButton>

						</Box>
					</Stack>
				</Box>
			</Paper>
		);

	}

}

export default Header;

